import React from "react";
import './App.css';

const App = () => {
  return (
    <>
      <div class="container">
        {/* <!-- Header Section --> */}
        <header>
          <h1>MyQRMenu</h1>
          <p>Create and share your restaurant's menu effortlessly!</p>
        </header>

        {/* <!-- Demo Section --> */}
        <section class="demo">
          <h2>Quick, Simple & Convenient</h2>
          <p>
            With MyQRMenu, let your customers access your menu instantly by
            scanning a QR code with their smartphones. No physical menu needed,
            keeping it easy and hygienic!
          </p>
          <div class="icon">📱</div>
        </section>

        {/* <!-- Call-to-Action Button --> */}
        <a href="#get-started" class="cta-button">
          Get Started for Free
        </a>

        {/* <!-- Footer --> */}
        <footer>
          <p>&copy; 2024 MyQRMenu. All rights reserved.</p>
        </footer>
      </div>
    </>
  );
};

export default App;
